<template>
  <div class="main_box">
    <div id="header">
      <div @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24" />
      </div>
      <div style="font-size:16px;">支付结果</div>
      <div>
        <van-icon name="arrow-left" size="24" color="#fff" />
      </div>
    </div>
    <div class="check_box" v-if="showCheck">
      <div>请确认支付是否已完成</div>
      <div style="font-size:16px;color:red;" @click="checkOrder">已完成支付</div>
      <div style="color:#999;" @click="rePay">支付遇到问题,重新支付</div>
    </div>
    <div v-if="showSuccess" class="status_box">
      <div class="success_box">
        <van-icon name="success" color="#fff" size="90" />
      </div>
      <div class="pay_msg">支付成功</div>
      <div class="pay_price" v-if="false">￥{{price}}</div>
      <div class="success_btn" @click="toHome">完成</div>
    </div>
    <div v-if="showfail">
      <div class="fail_box">
        <van-icon name="fail" color="#fff" size="90" />
      </div>
      <div class="pay_msg">支付失败</div>
      <div class="pay_price" v-if="false">￥{{price}}</div>
      <div class="fail_btn" @click="rePay">重新支付</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      price: "2.00",
      showSuccess: false,
      showfail: false,
      showCheck: true
    };
  },
  created() {
    let order = this.$route.params.status;
    let fakePay = this.$route.query.fakePay;
    console.log(fakePay)
    if(fakePay){
      this.showCheck = false
      this.showSuccess = true
      return
    }
    if (order) {
      this.pollingOrder(order);
    }
  },
  beforeDestroy() {
    if (window.timer) {
      clearInterval(window.timer);
    }
  },
  methods: {
    rePay(){
      let routePath = '/orderdetail/' + this.$route.query.orderId + '?goods_recieve_type=' + this.$route.query.goods_recieve_type
      this.$router.replace(routePath)
    },
    toHome(){
      // let backlen = window.history.length - 1;
      // window.history.go(-backlen + 1);
      this.$closePage()
    },
    async checkOrder() {
      if (window.timer) {
        clearInterval(window.timer);
      }
      try {
        let { data: res } = await this.$http.post("/api/payment/paid", {
          orderNo: this.$route.params.status,
          store_id:this.$getUser()?.store_id
        });
        if(res.statusCode !== 0){
          return this.$toast.fail('查询失败')
        }
        this.showCheck = false
        this.showSuccess = true
      } catch (e) {
        this.$toast.fail('网路错误')
        return { statusCode: -1 };
      }
    },
    pollingOrder(order_no) {
      let count = 0;
      window.timer = setInterval(async () => {
        if (count >= 999) {
          return clearInterval(window.timer);
        }
        let res = await this.getOrderStatus(order_no);
        if (res.statusCode == 0) {
          clearInterval(window.timer);
          this.showCheck = false;
          this.showSuccess = true;
        }
        count++;
      }, 1000);
    },
    async getOrderStatus(order_no) {
      try {
        let { data: res } = await this.$http.post("/api/payment/paid", {
          orderNo: order_no,
          store_id:this.$getUser()?.store_id
        });
        return res;
      } catch (e) {
        console.log(e);
        return { statusCode: -1 };
      }
    }
  }
};
</script>

<style lang="less" scoped>
.main_box {
  position: relative;
  padding-top: 5.5rem;
  height: 100vh;
}
.check_box {
  margin: 200px 20px;
  background-color: #f9f9f9;
  padding: 20px 60px;
  border-radius: 10px;
  > div {
    padding: 10px;
    border-bottom: 1px solid #fff;
  }
}
.status_box {
  display: flex;
  align-items: center;
  flex-direction: column;
  .success_box {
    margin-top: 100px;
    width: 120px;
    height: 120px;
    background-color: #00c250;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail_box {
    margin-top: 100px;
    width: 120px;
    height: 120px;
    background-color: red;
    border-radius: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .pay_msg {
    margin-top: 20px;
    font-size: 18px;
    color: #00c250;
  }
  .pay_price {
    margin-top: 20px;
    font-size: 40px;
  }
  .success_btn {
    margin-top: 100px;
    width: 12.5rem;
    height: 2.5rem;
    background-color: var(--fc--);
    border-radius: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  .fail_btn {
    margin-top: 100px;
    width: 200px;
    height: 40px;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
}
</style>